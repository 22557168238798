import axiosIns from "../libs/axios";

export const resetPassword = (email) => {
  return axiosIns({
    method: "POST",
    url: `/api/v2/userManagement/request-password-reset?email=${email}`,
  });
};

export const allVesselDto = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/vessels/myFleetDTO`,
  });
};

export const allRoutes = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/routes/all`,
  });
};

export const routePatterns = (routeId) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/routes/${routeId}`,
  });
};

export const allMainEngines = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/engineSubTypes/me/all`,
  });
};

export const allAuxEngines = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/engineSubTypes/aux/all`,
  });
};

export const allBoilerType = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/engineSubTypes/boilers/all`,
  });
};

export const allFuelTypes = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuels/fuelFamilies/all`,
  });
};

export const allVesselTypes = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/vessels/vesselTypes/all`,
  });
};

export const getOwner = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/userManagement/getCompany`,
  });
};

export const yearWiseFleetResult = (data) => {
  let url = "/api/v2/FE/Optimize";
  return axiosIns({
    method: "POST",
    url: url,
    data: data,
  });
};

export const allRevenueByOwner = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/revenue/byOwner`,
  });
};

export const emissionsInventoryByOwner = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/emissionsInventory/byOwner`,
  });
};

export const changeRevenue = (vesselId, routeId, qusdrt) => {
  return axiosIns({
    method: "PUT",
    url: `/api/v2/revenue/upsert?vesselId=${vesselId}&routeId=${routeId}&qUSDRT=${qusdrt}`,
  });
};

export const changeEmissions = (vesselId,date, emissionsToDate_gCO2eq,globalEmissionToDate, globalWorkToDate, energyToDate_MJ) => {
  return axiosIns({
    method: "PUT",
    url: `/api/v2/emissionsInventory/upsert?vesselId=${vesselId}&date=${date}&EU_emissionsToDate_mtCO2eq=${emissionsToDate_gCO2eq}&EU_energyToDate_GJ=${energyToDate_MJ}&global_emissionsToDate_mtCO2eq=${globalEmissionToDate}&global_workToDate_mtNm=${globalWorkToDate}`,
  });
};

export const routeKPIs = (routeId, speed) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/routes/routeKPI/${routeId}/${speed}`,
  });
};

export const changeRoute = (vesselId, routeId) => {
  return axiosIns({
    method: "PUT",
    url: `/api/v2/vessels/assignRoute/${vesselId}/${routeId}`,
  });
};

export const allFAQ = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/FAQ/all`,
  });
};

export const allVesrionInfo = () => {
  return axiosIns({
    method: "GET",
    url: `/actuator/info`,
  });
};

export const getAllYears = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/FE/AvailableYears`,
  });
};

export const allPriceForecastScenario = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuelPrices/allScenarios`,
  });
};

export const allLegislativeScenario = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/legislation/allScenarios`,
  });
};

export const getOneScenario = (scenarioId) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/legislation/scenario/${scenarioId}`,
  });
};

export const refreshCii = (cii) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/legislation/refreshCIICurve?z_cii=${cii}`,
  });
};

export const regulatoryContext = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/legislation/RegulatoryParameters`,
  });
};

export const addLegislativeScenario = (name) => {
  return axiosIns({
    method: "POST",
    url: `/api/v2/legislation/addScenario?scName=${name}`,
  });
};

export const updateScenario = (data) => {
  return axiosIns({
    method: "PUT",
    url: `/api/v2/legislation/upsertScenario`,
    data: data,
  });
};

export const addFuelPriceScenario = (name) => {
  return axiosIns({
    method: "POST",
    url: `/api/v2/fuelPrices/addNewScenario?scName=${name}`,
  });
};

export const getAllBasins = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuelPrices/allBasins`,
  });
};

export const getAllFuelTypes = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuels/fuelTypes/all`,
  });
};

export const updateTemplate = (data) => {
  return axiosIns({
    method: "PUT",
    url: `/api/v2/fuelPrices/updateScenarioTemplate`,
    data: data,
  });
};

export const refinePrices = (unit, data) => {
  return axiosIns({
    method: "PUT",
    url: `/api/v2/fuelPrices/refineScenarioPrices?units=${unit}`,
    data: data,
  });
};

export const requestResult = (caseId, email, file) => {
  return axiosIns({
    method: "POST",
    url: `/api/v2/FE/requestResultsWithPDF/${caseId}?email=${email}`,
    data: file,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getAllFuelPriceByBasin = (scenarioId, basinId) => {
  return axiosIns({
    method: "GET",
    url: `api/v2/fuelPrices/byBasin/${scenarioId}/${basinId}`,
  });
};

export const getAllBasinByScenario = (scenarioId) => {
  return axiosIns({
    method: "GET",
    url: `api/v2/fuelPrices/Basins/${scenarioId}`,
  });
};
