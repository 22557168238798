import { useState } from "react";
import { getAllFuelTypes } from "../api/data";
// TODO: the legend seems to depend on the fuel name, rather than id. This is a fragile design. 
export const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const legendContainer = document.getElementById(options.containerID);
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

  

    getAllFuelTypes()
      .then((res) => {
      const allFuelFamily = res.data;      
      const groupedFuels = {};
      allFuelFamily.forEach((fuelFamily) => {
        groupedFuels[fuelFamily.family.fuelFamilyName] = []; // Initialize an array for each fuel family
        allFuelFamily.forEach((fuel) => {
          if (fuel.family.fuelFamilyName === fuelFamily.family.fuelFamilyName) {
            groupedFuels[fuelFamily.family.fuelFamilyName].push(fuel);
          }
        });
      });
      while (legendContainer.firstChild) {
        legendContainer.firstChild.remove();
      }
      Object.keys(groupedFuels).forEach((fuel) => {
        const fuelGroup = document.createElement("div");
        const fuelOriginIds = [0, 1, 2]; 

        fuelOriginIds.forEach((id) => {
          const isIdMissing = !groupedFuels[fuel].some(groupedFuel => groupedFuel?.origin?.id === id);
          
          if (isIdMissing) {
            groupedFuels[fuel].splice(id, 0, []);
          }
        });

        groupedFuels[fuel].forEach((groupedFuel) => {
          const fuelItemGroup = document.createElement("div");
          fuelItemGroup.className = "html-legend-group";
            
          items.forEach((i, index) => {
            if(groupedFuel.fuelTypeName === i.text){
              const itemDiv = document.createElement("div");
              itemDiv.className = "d-flex flex-column custom-mr-2";
              const itemContent = document.createElement("div");
              itemContent.className = "html-legend-text";
              itemContent.className = "p-2 d-flex align-items-center gap-2 cursor-pointer";
              const colorBox = document.createElement("div");
              colorBox.className = "color-box";
              colorBox.style.backgroundColor = i.fillStyle;

              const fuelName = document.createElement("span");
              fuelName.textContent = i.text.replace(/ family: .*$/, '');
              fuelName.style.textDecoration = i.hidden ? "line-through" : "";

              itemContent.appendChild(colorBox);
              itemContent.appendChild(fuelName);

              itemDiv.appendChild(itemContent);
              fuelItemGroup.appendChild(itemDiv);
              fuelGroup.appendChild(fuelItemGroup)


              itemDiv.addEventListener("click", () => {
                chart.setDatasetVisibility(
                  i.datasetIndex,
                  !chart.isDatasetVisible(i.datasetIndex)
                );
                chart.update();
              });
            }
          });
          fuelGroup.appendChild(fuelItemGroup)
          legendContainer.appendChild(fuelGroup);
        });

      })
    });
    
  },
};
